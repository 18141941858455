<template>
   <div class="min-h-screen bg-gray-100 flex flex-col justify-center">
    <div class="md:mx-auto md:w-full px-4 md:px-0 md:max-w-4xl">
      <div class="grid grid-cols-12 gap-4">
        <div class="col-span-12 md:col-span-8">
          <h2 class="font-semibold text-2xl text-gray-800 pb-4">Esqueceu sua senha ?</h2>
          <label class="font-semibold text-base text-gray-800 pb-2 block">Sua conta é:</label>
          <div class="grid grid-cols-12 mb-4">
            <div @click="option = 'consultor'" class="col-span-12 md:col-span-4 text-center py-3 rounded-full md:mr-2 mb-2 md:mb-0 cursor-pointer bg-yellow-100" :class="option === 'consultor' ? 'border-b-2 border-yellow-200 text-center bg-yellow-300' :''">
              <label class="font-semibold text-sm text-gray-800">Profissionais no Projeto</label>
            </div>
            <div @click="option = 'pessoas'" class="col-span-12 md:col-span-4 text-center py-3 rounded-full  cursor-pointer bg-blue-100" :class="option === 'pessoas' ? 'border-b-2 border-terciary text-center bg-terciary' :''">
              <label class="font-semibold text-sm text-gray-800">Pessoas da Empresa</label>
            </div>
          </div>
          <label class="font-semibold text-base text-gray-800 pb-2 block">Digite o email da sua conta</label>
          <input v-model="form.email" placeholder="E-mail" type="email" class="border-none bg-gray-200 focus:ring-gray-800 px-3 py-3 mt-1 mb-3 text-base w-full" />
          <div class="grid grid-cols-12 gap-4">
            <div class="col-span-12 md:col-span-8">
              <router-link to="/registrar" class="font-normal text-base text-gray-800 hover:text-blue-500 block">Criar uma conta </router-link>
            </div>
            <div class="col-span-12 md:col-span-4">
              <button @click="enviarEmail" type="button" class="transition duration-200 bg-gray-900 hover:bg-gray-800 focus:bg-yellow-800 focus:shadow-sm focus:ring-4 focus:ring-gray-800 focus:ring-opacity-50 text-white w-full py-4 text-sm shadow-sm hover:shadow-md font-semibold text-center inline-block">
                <span class="inline-block mr-2">Continuar</span>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="w-4 h-4 inline-block">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 8l4 4m0 0l-4 4m4-4H3" />
                </svg>
              </button>
            </div>
          </div>
        </div>
        <div class="col-span-12 md:col-span-4 justify-self-center hidden md:block">
          <img class="md:mt-16" :src="require('../../assets/logo.png')" width="200" />
        </div>
      </div>
    </div>
  
  </div>
</template>

<script>
export default {
  name: 'EsqueciSenha',
  data: function () {
    return {
      option: 'consultor',
      form: {
        email: '',
      },
    }
  },
  methods: {
    getUrl(option) {
      if(option === 'pessoas') return 'pessoas/esqueceu-senha';
      return '/esqueceu-senha'
    },
    async enviarEmail() {
      const resp = await this.$http.post(`${this.getUrl(this.option)}`, this.form); 
      if (!resp.data.success) {
        this.$vToastify.error(resp.data.err);
      } else {
        this.$vToastify.success("Email de reset de senha enviado!");
        this.form.email = '';
      }
    }
  },
}
</script>
